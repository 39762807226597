import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeTh from '@angular/common/locales/th';
import localeZh from '@angular/common/locales/zh';
import { TranslateService } from '@ngx-translate/core';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { CustomTranslateHttpLoader } from './helpers/custom-translate-http-loader';
import {
    AVAILABLE_LANGUAGES,
    ENGLISH_LOCALE,
    ENGLISH_US_LOCALE,
    LOCALSTORAGE_LOCALE_KEY,
} from './helpers/locale.const';
import { take } from 'rxjs';

registerLocaleData(localeDe);
// Angular provides 'en-GB' locale for british format and 'en' locale for US format
// We care only about these two english formats because in the end we are able to select either English or English (US) format
// These locale need to be patched because we receive 'en-US' for english (US) and 'en' for english instead.
registerLocaleData(localeEn, 'en-US');
registerLocaleData(localeEnGB, 'en');
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localeZh);
registerLocaleData(localePt);
registerLocaleData(localePl);
registerLocaleData(localeNl);
registerLocaleData(localeCs);
registerLocaleData(localeTh);
registerLocaleData(localeRu);
registerLocaleData(localeHu, 'hu');
registerLocaleData(localeRo, 'ro');

/**
 * Sets correct language for TranslateService
 */
export function initLangSettings(translate: TranslateService, ccAuthService: CCAuthService): () => void {
    return () => configureTranslateService(translate, ccAuthService);
}

/**
 * Returns current language
 */
export function getLocale(translate: TranslateService): string {
    return translate.currentLang;
}

export function createTranslateLoader(http: HttpClient): CustomTranslateHttpLoader {
    // There is only one file for 'en' generated from loco so we have to use it for both 'en' and 'en-GB'
    // This is why we need custom loading function from CustomTranslateHttpLoader
    return new CustomTranslateHttpLoader(http);
}

export function configureTranslateService(translate: TranslateService, ccAuthService: CCAuthService): void {
    translate.addLangs(AVAILABLE_LANGUAGES);
    translate.setDefaultLang(ENGLISH_LOCALE);

    ccAuthService.currentSession$.pipe(take(1)).subscribe(() => {
        const currentUserLocale = ccAuthService.getCurrentUser()?.locale;
        if (currentUserLocale) {
            localStorage.setItem(LOCALSTORAGE_LOCALE_KEY, currentUserLocale);
            translate.use(extractSupportedLocale(currentUserLocale));
        } else {
            // getBrowserCultureLang() for english returns 'en' and for english (United States) 'en-US'
            // getBrowserLang() for both english and english (United States) returns 'en' but we want to distinguish them for correct date format
            const browserLang = translate.getBrowserCultureLang();
            const locale = localStorage.getItem(LOCALSTORAGE_LOCALE_KEY) ?? browserLang;
            translate.use(extractSupportedLocale(locale));
        }
    });
}

export function extractSupportedLocale(locale: string | undefined): string {
    let patchedLocale = locale;
    if (patchedLocale) {
        const index = patchedLocale.indexOf('-');
        if (index !== -1 && patchedLocale !== ENGLISH_US_LOCALE) {
            patchedLocale = patchedLocale.substring(0, index);
        }
        return patchedLocale.match(/en|en-US|de|cs|es|fr|hu|it|ja|ko|nl|pl|pt|ro|ru|th|zh/)
            ? patchedLocale
            : ENGLISH_LOCALE;
    } else {
        return ENGLISH_LOCALE;
    }
}
